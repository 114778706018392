<template>
  <div class="softwareComponentTypeEdit">
    <template v-if="softwareComponentType">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            Software Component Type
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="softwareComponentType.name"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.searchString') }}</label>
          <input
            v-model="softwareComponentType.searchString"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('SearchString') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.responsible') }}</label>
          <input
            v-model="softwareComponentType.responsible"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Responsible') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>Link</label>
          <input
            v-model="softwareComponentType.link"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Link') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.templateName') }}</label>
          <input
            v-model="softwareComponentType.templateName"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('TemplateName') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.timeoutInSec') }}</label>
          <input
            v-model="softwareComponentType.timeoutInSec"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('TimeoutInSec') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.licenceRequired') }}</label>
          <input
            v-model="softwareComponentType.licenceRequired"
            class="ml-3 mt-1"
            type="checkbox"
          >
          <div class="m-0 mb-3" />
          <label>{{ $t('softwareComponent.licenceName') }}</label>
          <input
            v-model="softwareComponentType.licenceName"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('LicenceName') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.multipleUrls') }}</label>
          <input
            v-model="softwareComponentType.multiple"
            class="ml-3 mt-1"
            type="checkbox"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('softwareComponent.automatedConfigUpdate') }}</label>
          <input
            v-model="softwareComponentType.automatedConfiguration"
            class="ml-3 mt-1"
            type="checkbox"
          >
        </div>
        <button
          :disabled="!softwareComponentType.name"
          class="btn btn-sm btn-primary float-right"
          @click.prevent="updateSoftwareComponentType()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoftwareComponentTypeEdit",
  mixins: [
    errorMixin
  ],
  props: {
    softwareComponentType: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true
    }
  },
  methods: {
    updateSoftwareComponentType () {
      let softwareComponentTypeDto = {
        'id': this.softwareComponentType.id,
        'name': this.softwareComponentType.name,
        'multiple': this.softwareComponentType.multiple,
        'searchString': this.softwareComponentType.searchString,
        'templateName': this.softwareComponentType.templateName,
        'timeoutInSec': this.softwareComponentType.timeoutInSec,
        'automatedConfiguration': this.softwareComponentType.automatedConfiguration,
        'licenceRequired': this.softwareComponentType.licenceRequired,
        'licenceName': this.softwareComponentType.licenceName,
        'responsible': this.softwareComponentType.responsible,
        'link': this.softwareComponentType.link
      };
      this.axios.put('/SoftwareComponent/UpdateSoftwareComponentType', softwareComponentTypeDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('softwareComponent.typeUpdatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>