<template>
  <div class="softwareComponentTypeAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Software Component Type
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="softwareComponentType.name"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.searchString') }}</label>
        <input
          v-model="softwareComponentType.searchString"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('SearchString') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.responsible') }}</label>
        <input
          v-model="softwareComponentType.responsible"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Responsible') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Link</label>
        <input
          v-model="softwareComponentType.link"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Link') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.templateName') }}</label>
        <input
          v-model="softwareComponentType.templateName"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('TemplateName') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.timeoutInSec') }}</label>
        <input
          v-model="softwareComponentType.timeoutInSec"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('TimeoutInSec') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.licenceRequired') }}</label>
        <input
          v-model="softwareComponentType.licenceRequired"
          class="ml-3 mt-1"
          type="checkbox"
        >
        <div class="m-0 mb-3" />
        <label>{{ $t('softwareComponent.licenceName') }}</label>
        <input
          v-model="softwareComponentType.licenceName"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('LicenceName') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.multipleUrls') }}</label>
        <input
          v-model="softwareComponentType.multiple"
          class="ml-3 mt-1"
          type="checkbox"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('softwareComponent.automatedConfigUpdate') }}</label>
        <input
          v-model="softwareComponentType.automatedConfiguration"
          class="ml-3 mt-1"
          type="checkbox"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!softwareComponentType.name"
        @click.prevent="addSoftwareComponentType()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoftwareComponentTypeAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      softwareComponentType: {}
    }
  },
  methods: {
    async addSoftwareComponentType () {
      let postObject = {
        'name': this.softwareComponentType.name,
        'multiple': this.softwareComponentType.multiple,
        'searchString': this.softwareComponentType.searchString,
        'templateName': this.softwareComponentType.templateName,
        'timeoutInSec': this.softwareComponentType.timeoutInSec,
        'automatedConfiguration': this.softwareComponentType.automatedConfiguration,
        'licenceRequired': this.softwareComponentType.licenceRequired,
        'licenceName': this.softwareComponentType.licenceName,
        'responsible': this.softwareComponentType.responsible,
        'link': this.softwareComponentType.link
      }
      await this.axios.post('/SoftwareComponent/AddSoftwareComponentType', postObject)
        .then(() => {
          this.$snotify.success(this.$t('softwareComponent.typeAddedSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.softwareComponentTypeAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>