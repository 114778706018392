<template>
  <div class="softwareComponentTypeList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(softwareComponentTypes)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(softwareComponentTypes)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="searchStringFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="searchStringFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a String"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('searchStringFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="templateNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="templateNameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('templateNameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="timeoutFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="timeoutFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a timeout"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('timeoutFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="licenceFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="licenceFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a licence"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('licenceFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <template
          slot="multipleTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            >
          </td>
        </template>

        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group-vertical">
              <div class="btn-group">
                <button
                  class="btn btn-primary btn-sm"
                  @click="showSidebarEdit(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>Edit</span>
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  @click="openConfirmModal(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2 gray"
                    icon="trash"
                  />
                  <span class="pr-3">Remove</span>
                </button>
              </div>
              <router-link
                :to="{ path: `/software-component-types/${ props.dataItem.id }` }"
                class="btn btn-primary btn-sm"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="eye"
                  style="vertical-align: middle;"
                />
                <span>Commands</span>
              </router-link>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <SoftwareComponentTypeAdd
          v-if="addingElement"
          @reload="reloadSoftwareComponentTypes"
        />
        <SoftwareComponentTypeEdit
          v-else
          :software-component-type="softwareComponentType"
          @reload="reloadSoftwareComponentTypes"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="softwareComponentType">
        {{ $t('sureToDelete', {'0' : softwareComponentType.name}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeSoftwareComponentType()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import SoftwareComponentTypeAdd from '@/components/SoftwareComponent/SoftwareComponentTypeAdd';
import SoftwareComponentTypeEdit from '@/components/SoftwareComponent/SoftwareComponentTypeEdit';

export default {
  name: "SoftwareComponentTypeList",
  components: {
    SweetModal,
    Sidebar,
    SoftwareComponentTypeAdd,
    SoftwareComponentTypeEdit
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      softwareComponentType: null,
      softwareComponentTypes: null,
      showSidebar: false,
      addingElement: false,
      deleteIndex: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: false,
          title: 'Id',
          width: '90px'
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: 'nameFilter'
        },
        {
          field: 'searchString',
          filterable: false,
          filter: 'text',
          title: this.$t('softwareComponent.searchString'),
          filterCell: 'searchStringFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'responsible',
          filterable: true,
          filter: 'text',
          title: this.$t('softwareComponent.responsible'),
          filterCell: 'searchStringFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'templateName',
          filterable: false,
          filter: 'text',
          title: this.$t('softwareComponent.templateName'),
          filterCell: 'templateFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'timeoutInSec',
          filterable: false,
          filter: 'text',
          title: this.$t('timeout'),
          filterCell: 'timeoutFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'licenceRequired',
          filterable: false,
          filter: 'text',
          title: this.$t('softwareComponent.licenceRequired'),
          cell: 'multipleTemplate',
          width: '130px',
          hideOn: ['mdDown']
        },
        {
          field: 'licenceName',
          filterable: false,
          filter: 'text',
          title: this.$t('softwareComponent.licenceName'),
          filterCell: 'licenceFilter',
        },
         {
          field: 'multiple',
          filterable: false,
          filter: 'text',
          title: this.$t('softwareComponent.multipleUrls'),
          cell: 'multipleTemplate',
          width: '120px',
          hideOn: ['mdDown']
        },
        {
          field: 'automatedConfiguration',
          filterable: false,
          filter: 'text',
          title: 'Auto. Configuration',
          cell: 'multipleTemplate',
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '185px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.softwareComponentType')
    }
  },
  created () {
    this.getSoftwareComponentTypes();
  },
  methods: {
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentSoftwareComponentType(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentSoftwareComponentType (softwareComponentType) {
      this.softwareComponentType = Object.assign({}, softwareComponentType); 
    },
    //end sidebar

    //api
    reloadSoftwareComponentTypes () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getSoftwareComponentTypes();
    },
    openConfirmModal (data) {
      this.setCurrentSoftwareComponentType(data);
      this.deleteIndex = data.id;
      this.$refs.confirmDelete.open();
    },
    removeSoftwareComponentType () {
      this.axios.delete(`/SoftwareComponent/DeleteSoftwareComponentType?id=${ this.deleteIndex }`)
      .then(() => {
        this.$snotify.success(this.$t('softwareComponent.typeDeletedSuccessfully'));
        this.$emit("reloadAuditLogs");
        this.reloadSoftwareComponentTypes();
      })
      .finally(() => {
        this.deleteIndex = null;
        this.$refs.confirmDelete.close();
      });
    },
    getSoftwareComponentTypes () {
      this.loading = true;
      this.axios.get('/SoftwareComponent/GetAllSoftwareComponentTypes')
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponentTypes = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.softwareComponentTypeList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>